<template>
  <div class="reports-section standard-page">
    <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 pt-3 pb-4">
      <!-- Header and Actions -->
      <div class="d-flex flex-wrap flex-md-nowrap justify-space-between mb-8">
        <v-tabs
          left
          v-model="selectedMenuIndex"
          background-color="transparent"
          @change="handleTabChange"
        >
          <v-tab v-for="key in menuKeys" :key="key">
            <v-icon left>
              {{ iconsMap[key] }}
            </v-icon>

            {{ typesMap[key] }}
          </v-tab>
        </v-tabs>

        <!-- Actions -->
        <div class="d-flex flex-grow-1 flex-md-grow-0 flex-wrap flex-md-nowrap align-center justify-center justify-md-end mt-4 mt-md-0">
          <!-- Delete Campaigns -->
          <v-btn
            v-if="selectedItems.length"
            depressed
            class="mr-3"
            color="primary"
            :disabled="isMakingRequest"
            :class="{ 'shadow--primary': !isMakingRequest }"
            @click="shouldShowDeleteDialog = true"
          >
            <v-icon left>delete</v-icon>
            Delete
          </v-btn>
        </div>
      </div>

      <v-row>
        <!-- Show the input -->
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="searchQuery"
            label="Search"
            placeholder="Report title"
            outlined
            dense
            hide-details
            clearable
            append-icon="search"
            @keypress.enter="currentPage = 1"
            @click:append="currentPage = 1"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Data Table for when it's not unlocked accounts -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableHeaders"
      :items="response.data"
      :server-items-length="response.total"
      :page="currentPage"
      :items-per-page="queryPerPage"
      @update:page="currentPage = $event"
      @update:items-per-page="queryPerPage = $event"
      show-select
      selectable-key="id"
      :mobile-breakpoint="100"
      class="box-shadow-soft influencer-insight-history-data-table"
    >
      <!-- For created at -->
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex flex-column"
              v-bind="attrs"
              v-on="on"
            >
              <!-- Show the date -->
              <div class="white-space-nowrap">
                {{ formatRelativeDate(item.created_at, "MMMM D, YYYY") }}
              </div>

              <!-- If it's more than 7 days -->
              <div v-if="differenceFromNow(item.created_at) > 7">
                {{ formatRelativeDate(item.created_at, "h:mm A") }}
              </div>
            </div>
          </template>
          <span>
            {{ formatDate(item.created_at) }}
          </span>
        </v-tooltip>
      </template>

      <!-- For Title -->
      <template v-slot:item.title="{ item }">
        <div>
          {{ item.title }}
        </div>
      </template>

      <!-- For the last/action column -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            :loading="loadingItems.includes(item.symbol)"
            :disabled="loadingItems.includes(item.symbol)"
            @click="downloadFile(item)"
            width="170"
            depressed
            small
          >
            Download {{ item.filetype === "csv" ? "XLSX" : item.filetype.toUpperCase() }}

            <v-icon right>
              download
            </v-icon>
          </v-btn>


          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="170" dense>
              <v-list-item
                @click="showEditDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Rename
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="showDeleteDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete {{ selectedItems.length }} {{ selectedItems.length > 1 ? "reports" : "report" }}.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this export
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            v-model="form.title"
            label="Report Title"
            placeholder="Optional"
            @input="$v.form.title.$touch()"
            @blur="$v.form.title.$touch()"
            :hide-details="!$v.form.title.$anyError"
            :error-messages="$v.form.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="mb-6"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node package
import downloadFileHelper from "@/helpers/downloadFile"

// Import helper functions
import { maxLength } from "vuelidate/lib/validators"

// Import child component
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip.vue")

// Export the SFC
export default {
  // Name of the component
  name: "GeneratedReports",

  // Register child components
  components: {
    ProfileChip
  },

  // Define local data variables
  data: () => ({
    // The tab menu that's been selected
    selectedMenuIndex: 0,

    // The buttons that have been clicked and are being loaded
    loadingItems: [],

    // The tab options
    typesMap: {
      "influencer-discovery": "Influencer Discovery",
      "paid-collaborations": "Paid Collaborations",
    },

    // The icons to show in the tab item
    iconsMap: {
      "influencer-discovery": "person_search",
      "paid-collaborations": "query_stats",
    },

    // Whether or not we're loading
    isMakingRequest: false,

    // The selected items to be deleted
    selectedItems: [],
    shouldShowDeleteDialog: false,

    // The selected item to be used with various options
    selectedItem: null,
    shouldShowEditDialog: false,

    // The edit form
    form: {
      title: "",
    },

    tableHeaders: [
      {
        text: "Created At",
        value: "created_at",
        sortable: false
      },
      {
        text: "Title",
        value: "title",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "end"
      }
    ]
  }),

  // Define computable data variables
  computed: {
    /**
     * Get API response from the Vuex store
     *
     * @returns {Object}
     */
    response() {
      const selectedType = Object.keys(this.typesMap)[this.selectedMenuIndex]

      return this.$store.getters["reports/response"][selectedType]
    },

    /**
     * The query object set in Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["reports/query"]
    },

    /**
     * Get the current page number in pagination
     */
    currentPage: {
      get() {
        return this.query.page
      },

      set(value) {
        this.$store.dispatch("reports/updateQuery", {
          key: "page",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the number of items per page
     */
    queryPerPage: {
      get() {
        return this.query.perPage
      },

      set(value) {
        this.$store.dispatch("reports/updateQuery", {
          key: "perPage",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    // Use getter and setter to link the value with vuex store
    searchQuery: {
      /**
       * Readonly search input value
       *
       * @returns {String}
       */
      get() {
        return this.query.search
      },

      /**
       * Write only function to modify the value in Vuex store
       *
       * @param {String} value
       * @returns {void}
       */
      set(value) {
        // If the value is different
        if (this.query.search === value) return

        this.$store.dispatch("reports/updateQuery", {
          key: "search",
          value
        })
      }
    },

    /**
     * The object keys for the menu options
     *
     * @returns {Array}
     */
    menuKeys() {
      return Object.keys(this.typesMap)
    },
  },

  // Define vuelidate validation rules
  validations: {
    form: {
      title: {
        maxLength: maxLength(100)
      }
    }
  },

  // Define Options API method functions
  methods: {
    /**
     * When the tab changes, update the query parameter
     *
     * @returns {void}
     */
    handleTabChange() {
      this.$router.replace({
        name: this.$route.name,
        query: {
          tab: this.menuKeys[this.selectedMenuIndex]
        }
      })
    },

    /**
     * Download the report file from API's response
     *
     * @param {Object} item | The report model
     * @returns {void}
     */
    async downloadFile(item) {
      this.isMakingRequest = true
      this.loadingItems.push(item.symbol)

      try {
        // Find the items to skip
        const response = await axios({
          url: `/api/generated-reports/${item.id}/download`,
          method: "POST"
        })

        // Download the file using this
        downloadFileHelper(response.data)
      } catch (error) {
        logger({ type: "Error while downloading report", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred." })
      } finally {
        const index = this.loadingItems.findIndex((symbol) => symbol === item.symbol)
        this.loadingItems.splice(index, 1)

        this.isMakingRequest = false
      }
    },

    /**
     * Fetch the items
     *
     * @returns {void}
     */
    async fetchItems() {
      // If it's making request
      if (this.isMakingRequest) return

      // Mark as loading
      this.isMakingRequest = true
      // Dispatch action to fetch items
      await this.$store.dispatch("reports/fetchItems")
      // Mark as not loading
      this.isMakingRequest = false
    },


    /**
     * Show the delete dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showDeleteDialog(item) {
      this.selectedItems = [item]
      this.shouldShowDeleteDialog = true
    },

    /**
     * Delete the selected items
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Go through each selected items
      for (const item of this.selectedItems) {
        // Use vuex store action
        await this.$store.dispatch("reports/deleteItem", {
          id: item.id,
          showToast: false,
          fetchItems: false
        })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Reset the selected items
      this.selectedItems = []

      // Hide the dialog
      this.shouldShowDeleteDialog = false

      // Refresh the items
      this.fetchItems()
    },

    /**
     * Show the edit dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showEditDialog(item) {
      // Set the form values
      this.form.title = item.title || ""

      this.$v.form.$reset()

      this.selectedItem = item
      this.shouldShowEditDialog = true
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("reports/updateItem", {
        id: this.selectedItem.id,
        title: this.form.title
      })

      this.isMakingRequest = false
    },
  },

  /**
   * As soon as the template's been rendered
   *
   * @returns {void}
   */
  created() {
    // Fetch the items
    this.fetchItems()

    // Now switch the tab if needed
    if (this.$route.query.tab) {
      // Find the index
      const index = this.menuKeys.findIndex((search) => search === this.$route.query.tab)

      // If found switch to it
      if (index !== -1) {
        this.selectedMenuIndex = index
      }
    }
  },
}
</script>

<style lang="stylus">
.reports-section
  .v-slide-group__prev.v-slide-group__prev--disabled
    display none !important

  .contain-max-width
    max-width 24em

    input, label
      font-weight 500 !important
</style>
